import { Controller } from 'stimulus'
import { iframeResizer } from 'iframe-resizer'
import { SlideboxGlobals } from 'packs/embed'

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', (event: Event) => {
      event.preventDefault()
      let slideboxGlobals = <SlideboxGlobals>this.application['slideboxGlobals']

      let modal = slideboxGlobals.findIFrameModal()
      let src = (<HTMLLinkElement>this.element).href
      
      if (modal) {
        modal.remove()
        modal = slideboxGlobals.createIFrameModal(src)
        this.showModal(modal)
        // let iframe =
        //   <HTMLIFrameElement>modal.querySelector('iframe[name=slidebox_deck]')
        // console.log(iframe['iFrameResizer'])
        // iframe['iFrameResizer'].sendMessage(
        //   {
        //     type: 'set_deck', 
        //     deckId: 'Deck__8'
        //   }
        // )
      } else {
        modal = slideboxGlobals.createIFrameModal(src)
        this.showModal(modal)
      }
    })
  }

  showModal(modal: HTMLDivElement) {
    modal.style.display = 'block'
    setTimeout(() => { modal.style.opacity = '1' }, 0)
  }
}
