import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

export type SlideboxGlobals = {
  getAllIframes: () => HTMLIFrameElement[]
  findIFrameModal: () => HTMLDivElement | null
  createIFrameModal: (src: string, searchQuery?: string | null,
                      selectedSlideId?: string | null) => HTMLDivElement
}

if (typeof(window['slideboxApplication'] === 'undefined')) {
  window['slideboxApplication'] = Application.start()
  const context =
    require.context("../pack_modules/embed", true, /\.ts$/)
  window['slideboxApplication'].load(definitionsFromContext(context))

  let slideboxGlobals: SlideboxGlobals = {
    getAllIframes: function() {
      let iframes = <Element[]>[].slice.call(document.querySelectorAll(
        `iframe[name=slidebox][data-controller=embed]`
        ))
      let modalIframe = document.querySelector(
        `iframe[name=slidebox_deck][data-controller=embed]`
      )
      if (modalIframe) { iframes.push(modalIframe) }
      return <HTMLIFrameElement[]>iframes
    },
    findIFrameModal: function() {
      let iframe = document.querySelector(
        `iframe[name=slidebox_deck][data-controller=embed]`
      )
      if (iframe) {
        let modal = iframe.closest('#slidebox-modal')
        if (modal) { return <HTMLDivElement>modal }
        throw 'modal of iframe not found'
      }
      else { return null }
    },
    createIFrameModal: function(src, searchQuery, selectedSlideId) {
      let modal = document.createElement('div')
      modal.id = 'slidebox-modal'
      modal.style.setProperty('position', 'fixed', 'important')
      modal.style.setProperty('z-index', '999999', 'important')
      modal.style.display = 'none'
      modal.style.top = '0'
      modal.style.bottom = '0'
      modal.style.left = '0'
      modal.style.right = '0'
      modal.style.overflow = 'hidden'
      modal.style.background = 'rgba(0, 0, 0, 0.7)'
      modal.style.width = '100%'
      modal.style.height = '100%'
      modal.style.opacity = '0'
      modal.style.transition = 'opacity 0.5s ease'
      modal.addEventListener('click', (e)=>{
        if (e.target == e.currentTarget){
          modal.style.display = 'none'
        }
      })
      let iframeContainer = document.createElement('div')
      iframeContainer.style.position = 'absolute'
      iframeContainer.style.top = '0%'
      iframeContainer.style.bottom = '0%'
      iframeContainer.style.left = '0%'
      iframeContainer.style.right = '0%'
      iframeContainer.style.width = '100%'
      iframeContainer.style.height = '100%'
      let iframe = document.createElement('iframe')
      iframe.setAttribute('name', 'slidebox_deck')
      iframe.setAttribute('id', 'slidebox_deck')
      iframe.setAttribute('style', 'min-height:300px;')
      iframe.setAttribute('allowtransparency', 'true')
      iframe.setAttribute('data-controller', 'embed')
      iframe.setAttribute('data-embed-max-height-percentage', '86') // 100%-2*7%
      let observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.type == "attributes") {
            let targetElement = <HTMLElement>mutation.target
            if (targetElement) {
              targetElement.style.height = '100%'
              targetElement.style.minHeight = '100%'
            }
          }
        })
      })
      observer.observe(iframe, { attributes: true })
      let srcModal = new URL(src)
      srcModal.searchParams.set('as', 'modal')
      if (searchQuery) {
        srcModal.searchParams.set('search-query', searchQuery)
      }
      if (selectedSlideId) {
        srcModal.searchParams.set('selected-slide-id', selectedSlideId)
      }
      iframe.setAttribute('src', srcModal.toString())
      iframe.style.position = 'relative'
      iframeContainer.appendChild(iframe)
      modal.appendChild(iframeContainer)
      document.body.appendChild(modal)
      setTimeout(function() { modal.style.opacity = '1' }, 0)
      return modal
    }
  }

  window['slideboxApplication']['slideboxGlobals'] = slideboxGlobals

  // window['slideboxApplication'].getAllIframes =
  //   function() {
  //     let iframes = <Element[]>[].slice.call(document.querySelectorAll(
  //       `iframe[name=slidebox][data-controller=embed]`
  //       ))
  //     let modalIframe = document.querySelector(
  //       `iframe[name=slidebox_deck][data-controller=embed]`
  //     )
  //     if (modalIframe) { iframes.push(modalIframe) }
  //     return iframes
  //   }
  // window['slideboxApplication'].findIFrameModal =
  //   function() {
  //     let iframe = document.querySelector(
  //       `iframe[name=slidebox_deck][data-controller=embed]`
  //     )
  //     if (iframe) {
  //       let modal = iframe.closest('#slidebox-modal')
  //       if (modal) { return modal }
  //       throw 'modal of iframe not found'
  //     }
  //     else { return null }
  //   }
  // window['slideboxApplication'].createIFrameModal =
  //   function(src: string,
  //            searchQuery: string | null, selectedSlideId: string | null) {
  //     let modal = document.createElement('div')
  //     modal.id = 'slidebox-modal'
  //     modal.style.setProperty('position', 'fixed', 'important')
  //     modal.style.setProperty('z-index', '999999', 'important')
  //     modal.style.display = 'none'
  //     modal.style.top = '0'
  //     modal.style.bottom = '0'
  //     modal.style.left = '0'
  //     modal.style.right = '0'
  //     modal.style.overflow = 'hidden'
  //     modal.style.background = 'rgba(0, 0, 0, 0.7)'
  //     modal.style.width = '100%'
  //     modal.style.height = '100%'
  //     modal.style.opacity = '0'
  //     modal.style.transition = 'opacity 0.5s ease'
  //     modal.addEventListener('click', (e)=>{
  //       if (e.target == e.currentTarget){
  //         modal.style.display = 'none'
  //       }
  //     })
  //     let iframeContainer = document.createElement('div')
  //     iframeContainer.style.position = 'absolute'
  //     iframeContainer.style.top = '0%'
  //     iframeContainer.style.bottom = '0%'
  //     iframeContainer.style.left = '0%'
  //     iframeContainer.style.right = '0%'
  //     iframeContainer.style.width = '100%'
  //     iframeContainer.style.height = '100%'
  //     let iframe = document.createElement('iframe')
  //     iframe.setAttribute('name', 'slidebox_deck')
  //     iframe.setAttribute('id', 'slidebox_deck')
  //     iframe.setAttribute('style', 'min-height:300px;')
  //     iframe.setAttribute('allowtransparency', 'true')
  //     iframe.setAttribute('data-controller', 'embed')
  //     iframe.setAttribute('data-embed-max-height-percentage', '86') // 100%-2*7%
  //     let observer = new MutationObserver(function (mutations) {
  //       mutations.forEach(function (mutation) {
  //         if (mutation.type == "attributes") {
  //           let targetElement = <HTMLElement>mutation.target
  //           if (targetElement) {
  //             targetElement.style.height = '100%'
  //             targetElement.style.minHeight = '100%'
  //           }
  //         }
  //       })
  //     })
  //     observer.observe(iframe, { attributes: true })
  //     let srcModal = new URL(src)
  //     srcModal.searchParams.set('as', 'modal')
  //     if (searchQuery) {
  //       srcModal.searchParams.set('search-query', searchQuery)
  //     }
  //     if (selectedSlideId) {
  //       srcModal.searchParams.set('selected-slide-id', selectedSlideId)
  //     }
  //     iframe.setAttribute('src', srcModal.toString())
  //     iframe.style.position = 'relative'
  //     iframeContainer.appendChild(iframe)
  //     modal.appendChild(iframeContainer)
  //     document.body.appendChild(modal)
  //     setTimeout(function() { modal.style.opacity = '1' }, 0)
  //     return modal
  //   }
}
