import { Controller } from 'stimulus'
import { iframeResizer } from 'iframe-resizer'
import { MessageBetweenIFrames } from 'packs/show'
import { SlideboxGlobals } from 'packs/embed'

type IncomingMessage = {
  type: 'request_deck_modal', dynamicDeckPath: string, deckId: string,
  deckUid: string, boxId: string,
  searchQuery: string | null, selectedSlideId: string | null
} | {
  type: 'request_auth', nonce: string
} | {
  type: 'hide_modal'
} | {
  type: 'broadcast', data: MessageBetweenIFrames
}

export default class extends Controller {
  readonly element!: HTMLIFrameElement

  // When modal is open, prevent underlying body from scrolling
  // bodyScroll(enable: boolean) {
  //   let scrollContainer = document.scrollingElement || document.documentElement
  //   console.log('bodyscroll called')
  //   if (enable && window['slidebox_client_body']) {
  //     console.log('enabling')
  //     document.body.style.cssText = window['slidebox_client_body'].originalStyles
  //     scrollContainer.scrollTop = window['slidebox_client_body'].scrollTop
  //   } else if (!enable) {
  //     console.log('disabling')
  //     window['slidebox_client_body'] = {
  //       scrollTop: scrollContainer.scrollTop,
  //       originalStyles: document.body.style.cssText
  //     }
  //     let bodyHeight = document.body.clientHeight
  //     let bodyWidth = `calc(100% - ${window.innerWidth - document.body.clientWidth}px)`
  //     document.body.style.overflow = 'hidden'
  //     document.body.style.position = 'fixed'
  //     document.body.style.height = bodyHeight + 'px'
  //     console.log(bodyWidth)
  //     document.body.style.width = bodyWidth
  //     document.body.style.top = -window['slidebox_client_body'].scrollTop + 'px'
  //   }
  // }

  handleMessage(data) {
    if (data.iframe !== this.element) {
      console.error(`message sent to ${this.element.name} `+
                    `with address ${data.iframe.name}`)
      return
    }
    let slideboxGlobals = <SlideboxGlobals>this.application['slideboxGlobals']
    let message: IncomingMessage = data.message
    // console.log('EMBED CTRL got msg:')
    // console.log(data.message)
    if (message.type == 'request_auth') {
      this.element['iFrameResizer'].sendMessage(
        { type: 'response_auth', nonce: message.nonce,
          origin: window.location.origin }
      )
    }
    else if (message.type == 'request_deck_modal') {
      let deckId = message.deckId
      let deckUid = message.deckUid
      let boxId = message.boxId
      let searchQuery = message.searchQuery
      let selectedSlideId = message.selectedSlideId
      let modal = slideboxGlobals.findIFrameModal()
      if (modal) {
        let iframe =
          <HTMLIFrameElement>modal.querySelector('iframe[name=slidebox_deck]')
        if (iframe['iFrameResizer']) {
          iframe['iFrameResizer'].sendMessage(
            { type: 'set_deck', deckId: deckId, boxId: boxId,
              searchQuery: searchQuery, selectedSlideId: selectedSlideId }
          )
          // this.bodyScroll(false)
          modal.style.display = 'block' // show modal
          setTimeout(()=>{
            modal!.style.opacity = '1'
            iframe['iFrameResizer'].resize()
          }, 0)
        }
      }
      else {
        modal = slideboxGlobals.createIFrameModal(
          message.dynamicDeckPath.replace('DECK_UID', deckUid),
          searchQuery, selectedSlideId
        )
        // this.bodyScroll(false)
        modal.style.display = 'block' // show modal
        setTimeout(() => { modal!.style.opacity = '1' }, 0)
      }
    }
    else if (message.type == 'hide_modal') {
      let modal = slideboxGlobals.findIFrameModal()
      if (modal) {
        modal.style.display = 'none'
        modal.style.opacity = '0'
        // setTimeout(() => { this.bodyScroll(true) }, 0)
      }
    }
    else if (message.type == 'broadcast') {
      let data = message.data
      slideboxGlobals.getAllIframes().forEach((iframe)=>{
        if (iframe['iFrameResizer']) {
          iframe['iFrameResizer'].sendMessage({
            type: 'broadcast',
            data: data
          })
        }
      })
    }
  }

  connect() {
    // TODO get rid of the data-source property
    // we need to tell our clients about the changed embed code
    let dataSource = this.data.get('source')

    let iFrameResizerOptions = {
      log: false,
      //checkOrigin: window.location.origin,
      //checkOrigin: [new URL(src).origin],
      checkOrigin: [new URL(dataSource || this.element.src).origin],
      onMessage: this.handleMessage.bind(this)
    }
    let maxHeight = this.data.get('max-height-percentage')!
    if (maxHeight) {
      iFrameResizerOptions['maxHeight'] =
        window.innerHeight * parseFloat(maxHeight) * 0.01
      //iFrameResizerOptions['scrolling'] = true
    }
    iframeResizer(iFrameResizerOptions, this.element)

    if (dataSource){
      this.element.contentWindow!.location.assign(dataSource)
    }
    this.element.setAttribute('allowtransparency', 'true')
    this.element.style.width = '1px'
    this.element.style.minWidth = '100%'
    this.element.style.border = 'none'
  }
}
